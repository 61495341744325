import useTransitionStore from "./state/transitions";
import "./styles/styles.css";
import RouteHandler from "./Routes/Routes";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-NP6TBS4'
}

TagManager.initialize(tagManagerArgs)

ReactGA.initialize("G-C2CSBV7BQ9");

export default function App() {
  return <RouteHandler />;
}
