import A0 from "./TC4_00000.jpg";
import A1 from "./TC4_00001.jpg";
import A2 from "./TC4_00002.jpg";
import A3 from "./TC4_00003.jpg";
import A4 from "./TC4_00004.jpg";
import A5 from "./TC4_00005.jpg";
import A6 from "./TC4_00006.jpg";
import A7 from "./TC4_00007.jpg";
import A8 from "./TC4_00008.jpg";
import A9 from "./TC4_00009.jpg";

import A10 from "./TC4_00010.jpg";
import A11 from "./TC4_00011.jpg";
import A12 from "./TC4_00012.jpg";
import A13 from "./TC4_00013.jpg";
import A14 from "./TC4_00014.jpg";
import A15 from "./TC4_00015.jpg";
import A16 from "./TC4_00016.jpg";
import A17 from "./TC4_00017.jpg";
import A18 from "./TC4_00018.jpg";
import A19 from "./TC4_00019.jpg";

import A20 from "./TC4_00020.jpg";
import A21 from "./TC4_00021.jpg";
import A22 from "./TC4_00022.jpg";
import A23 from "./TC4_00023.jpg";
import A24 from "./TC4_00024.jpg";
import A25 from "./TC4_00025.jpg";
import A26 from "./TC4_00026.jpg";
import A27 from "./TC4_00027.jpg";
import A28 from "./TC4_00028.jpg";
import A29 from "./TC4_00029.jpg";

import A30 from "./TC4_00030.jpg";
import A31 from "./TC4_00031.jpg";
import A32 from "./TC4_00032.jpg";
import A33 from "./TC4_00033.jpg";
import A34 from "./TC4_00034.jpg";
import A35 from "./TC4_00035.jpg";
import A36 from "./TC4_00036.jpg";
import A37 from "./TC4_00037.jpg";
import A38 from "./TC4_00038.jpg";
import A39 from "./TC4_00039.jpg";

import A40 from "./TC4_00040.jpg";
import A41 from "./TC4_00041.jpg";
import A42 from "./TC4_00042.jpg";
import A43 from "./TC4_00043.jpg";
import A44 from "./TC4_00044.jpg";
import A45 from "./TC4_00045.jpg";
import A46 from "./TC4_00046.jpg";
import A47 from "./TC4_00047.jpg";
import A48 from "./TC4_00048.jpg";
import A49 from "./TC4_00049.jpg";

import A50 from "./TC4_00050.jpg";
import A51 from "./TC4_00051.jpg";
import A52 from "./TC4_00052.jpg";
import A53 from "./TC4_00053.jpg";
import A54 from "./TC4_00054.jpg";
import A55 from "./TC4_00055.jpg";
import A56 from "./TC4_00056.jpg";
import A57 from "./TC4_00057.jpg";
import A58 from "./TC4_00058.jpg";
import A59 from "./TC4_00059.jpg";

import A60 from "./TC4_00060.jpg";
import A61 from "./TC4_00061.jpg";
import A62 from "./TC4_00062.jpg";
import A63 from "./TC4_00063.jpg";
import A64 from "./TC4_00064.jpg";
import A65 from "./TC4_00065.jpg";
import A66 from "./TC4_00066.jpg";
import A67 from "./TC4_00067.jpg";
import A68 from "./TC4_00068.jpg";
import A69 from "./TC4_00069.jpg";

import A70 from "./TC4_00070.jpg";
import A71 from "./TC4_00071.jpg";
import A72 from "./TC4_00072.jpg";
import A73 from "./TC4_00073.jpg";
import A74 from "./TC4_00074.jpg";
import A75 from "./TC4_00075.jpg";
import A76 from "./TC4_00076.jpg";
import A77 from "./TC4_00077.jpg";
import A78 from "./TC4_00078.jpg";
import A79 from "./TC4_00079.jpg";

import A80 from "./TC4_00080.jpg";
import A81 from "./TC4_00081.jpg";
import A82 from "./TC4_00082.jpg";
import A83 from "./TC4_00083.jpg";
import A84 from "./TC4_00084.jpg";
import A85 from "./TC4_00085.jpg";
import A86 from "./TC4_00086.jpg";
import A87 from "./TC4_00087.jpg";
import A88 from "./TC4_00088.jpg";
import A89 from "./TC4_00089.jpg";

import A90 from "./TC4_00090.jpg";
import A91 from "./TC4_00091.jpg";
import A92 from "./TC4_00092.jpg";
import A93 from "./TC4_00093.jpg";
import A94 from "./TC4_00094.jpg";
import A95 from "./TC4_00095.jpg";
import A96 from "./TC4_00096.jpg";
import A97 from "./TC4_00097.jpg";
import A98 from "./TC4_00098.jpg";
import A99 from "./TC4_00099.jpg";

import A100 from "./TC4_00100.jpg";
import A101 from "./TC4_00101.jpg";
import A102 from "./TC4_00102.jpg";
import A103 from "./TC4_00103.jpg";
import A104 from "./TC4_00104.jpg";
import A105 from "./TC4_00105.jpg";
import A106 from "./TC4_00106.jpg";
import A107 from "./TC4_00107.jpg";
import A108 from "./TC4_00108.jpg";
import A109 from "./TC4_00109.jpg";

import A110 from "./TC4_00110.jpg";
import A111 from "./TC4_00111.jpg";
import A112 from "./TC4_00112.jpg";
import A113 from "./TC4_00113.jpg";
import A114 from "./TC4_00114.jpg";
import A115 from "./TC4_00115.jpg";
import A116 from "./TC4_00116.jpg";
import A117 from "./TC4_00117.jpg";
import A118 from "./TC4_00118.jpg";
import A119 from "./TC4_00119.jpg";

import A120 from "./TC4_00120.jpg";
import A121 from "./TC4_00121.jpg";
import A122 from "./TC4_00122.jpg";
import A123 from "./TC4_00123.jpg";
import A124 from "./TC4_00124.jpg";
import A125 from "./TC4_00125.jpg";
import A126 from "./TC4_00126.jpg";
import A127 from "./TC4_00127.jpg";
import A128 from "./TC4_00128.jpg";
import A129 from "./TC4_00129.jpg";

import A130 from "./TC4_00130.jpg";
import A131 from "./TC4_00131.png";
import A132 from "./TC4_00132.png";
import A133 from "./TC4_00133.png";
import A134 from "./TC4_00134.png";
import A135 from "./TC4_00135.png";
import A136 from "./TC4_00136.png";
import A137 from "./TC4_00137.png";
import A138 from "./TC4_00138.png";
import A139 from "./TC4_00139.png";

import A140 from "./TC4_00140.png";
import A141 from "./TC4_00141.png";
import A142 from "./TC4_00142.png";
import A143 from "./TC4_00143.png";
import A144 from "./TC4_00144.png";
import A145 from "./TC4_00145.png";
import A146 from "./TC4_00146.png";
import A147 from "./TC4_00147.png";
import A148 from "./TC4_00148.png";
import A149 from "./TC4_00149.png";

import A150 from "./TC4_00150.png";
import A151 from "./TC4_00151.png";
import A152 from "./TC4_00152.png";
import A153 from "./TC4_00153.png";
import A154 from "./TC4_00154.png";
import A155 from "./TC4_00155.png";
import A156 from "./TC4_00156.png";
import A157 from "./TC4_00157.png";
import A158 from "./TC4_00158.png";
import A159 from "./TC4_00159.png";

import A160 from "./TC4_00160.png";
import A161 from "./TC4_00161.png";

export const LandingSequence = Array.of(
  A0,
  A1,
  A2,
  A3,
  A4,
  A5,
  A6,
  A7,
  A8,
  A9,

  A10,
  A11,
  A12,
  A13,
  A14,
  A15,
  A16,
  A17,
  A18,
  A19,

  A20,
  A21,
  A22,
  A23,
  A24,
  A25,
  A26,
  A27,
  A28,
  A29,

  A30,
  A31,
  A32,
  A33,
  A34,
  A35,
  A36,
  A37,
  A38,
  A39,

  A40,
  A41,
  A42,
  A43,
  A44,
  A45,
  A46,
  A47,
  A48,
  A49,

  A50,
  A51,
  A52,
  A53,
  A54,
  A55,
  A56,
  A57,
  A58,
  A59,

  A60,
  A61,
  A62,
  A63,
  A64,
  A65,
  A66,
  A67,
  A68,
  A69,

  A70,
  A71,
  A72,
  A73,
  A74,
  A75,
  A76,
  A77,
  A78,
  A79,

  A80,
  A81,
  A82,
  A83,
  A84,
  A85,
  A86,
  A87,
  A88,
  A89,

  A90,
  A91,
  A92,
  A93,
  A94,
  A95,
  A96,
  A97,
  A98,
  A99,

  A100,
  A101,
  A102,
  A103,
  A104,
  A105,
  A106,
  A107,
  A108,
  A109,

  A110,
  A111,
  A112,
  A113,
  A114,
  A115,
  A116,
  A117,
  A118,
  A119,

  A120,
  A121,
  A122,
  A123,
  A124,
  A125,
  A126,
  A127,
  A128,
  A129,

  A130,
  A131,
  A132,
  A133,
  A134,
  A135,
  A136,
  A137,
  A138,
  A139,

  A140,
  A141,
  A142,
  A143,
  A144,
  A145,
  A146,
  A147,
  A148,
  A149,

  A150,
  A151,
  A152,
  A153,
  A154,
  A155,
  A156,
  A157,
  A158,
  A159,

  A160,
  A161
);
