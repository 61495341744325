import create from "zustand";

const useCustomizerStore = create((set) => ({
  showHotspots: false,
  toggleShowHotspots: (showHotspots) => {
    set(() => ({ showHotspots }));
  },

  model: 0,
  setModel: (model) => {
    set(() => ({ model }));
  },

  trimColor: ["#E20500", "#000000", ["#936445", "#000000"]],

  setTrimColor: (color, model) =>
    set((state) => {
      const arr = [...state.trimColor];
      arr[model] = color;
      return { trimColor: arr };
    }),

  setAllTrimColors: (color1, color2, color3) =>
    set((state) => {
      const arr = [color1, color2, color3];
      return { trimColor: arr };
    }),

  flipDirection: { direction: "", flipFlag: true },
  setCarFlipDirection: (obj) =>
    set((state) => ({
      flipDirection: obj,
    })),

  selectedHotspot: -1,
  setSelectedHotspot: (hotspot) =>
    set((state) => ({ selectedHotspot: hotspot })),

  selectedHotspotTitle: "",
  selectedHotspotDescription: "",
  setSelectedHotspotTitle: (title) =>
    set((state) => ({
      selectedHotspotTitle: title,
    })),
  setSelectedHotspotDescription: (description) =>
    set((state) => ({
      selectedHotspotDescription: description,
    })),

  futureSelectedHotspotTitle: "",
  futureSelectedHotspotDescription: "",
  setFutureSelectedHotspotTitle: (title) =>
    set((state) => ({
      futureSelectedHotspotTitle: title,
    })),
  setFutureSelectedHotspotDescription: (description) =>
    set((state) => ({
      futureSelectedHotspotDescription: description,
    })),

  selectedDisclaimer: "",
  setSelectedDisclaimer: (disclaimer) =>
    set((state) => ({
      selectedDisclaimer: disclaimer,
    })),

  futureSelectedDisclaimer: "",
  setfutureSelectedDisclaimer: (disclaimer) =>
    set((state) => ({
      futureSelectedDisclaimer: disclaimer,
    })),

  disclaimerOpen: false,
  setDisclaimerOpen: (bool) =>
    set((state) => ({
      disclaimerOpen: bool,
    })),

  selectedInterior: 0,
  setSelectedInterior: (number) =>
    set((state) => ({
      selectedInterior: number,
    })),

  filterHotspots: false,
  setFilterHotspots: (bool) =>
    set((state) => ({
      filterHotspots: bool,
    })),

  hotspotLocations: null,
  setHotspotLocations: (data) =>
    set((state) => ({
      hotspotLocations: data,
    })),
}));

export default useCustomizerStore;
