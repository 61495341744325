import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import LandingPage from "../components/UI/LandingPage/LandingPage";
import Loading from "../util/Loading";
const Main = React.lazy(() => import("../components"));

const RouteHandler = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route
        path="/main"
        element={
          <Suspense fallback={<Loading />}>
            <Main />
          </Suspense>
        }
      />
    </Routes>
  );
};
export default RouteHandler;
