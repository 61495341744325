import React, { useRef, useState, useEffect } from "react";
import ScrollToRevealTextContainer from "./ScrollToRevealTextContainer";
import Sequence from "./Sequence";
import "./styles/landingpage.css";

const SequenceContainer = () => {
  const [count, setCount] = useState(0);
  const ref = useRef();
  const requestRef = useRef();
  const previousTimeRef = useRef();
  const notStarted = useRef(true);

  const animate = (time) => {
    if (notStarted.current === true) {
    } else {
      if (previousTimeRef.current != undefined) {
        const deltaTime = time - previousTimeRef.current;

        if (count > 100) cancelAnimationFrame(requestRef.current);
        setCount((prevCount) => prevCount + deltaTime * 0.0001);
      }
      previousTimeRef.current = time;
    }
    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  const scrollHandler = (e) => {
    if (notStarted.current === false) return;
    notStarted.current = false;
  };

  return (
    <div
      onTouchMove={(e) => scrollHandler(e)}
      onWheel={(e) => scrollHandler(e)}
      style={{
        height: "100%",
        position: "relative",
        padding: "0",
        margin: "0",
        zIndex: "2",
      }}
    >
      <ScrollToRevealTextContainer count={count} />
      <Sequence ref={ref} count={count} />
    </div>
  );
};

export default SequenceContainer;
