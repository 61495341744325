import React, { useEffect, Suspense } from "react";
import Header from "../Header";
import "./styles/landingpage.css";
import SequenceContainer from "./SequenceContainer";
import useTransitionStore from "../../../state/transitions";
import scroll from "../../../assets/animations/MouseScroll_Desktop.json";
import swipe from "../../../assets/animations/SwipeUp_Mobile.json";
import styled from "styled-components";
import { AnimatePresence, motion, MotionConfig } from "framer-motion";
import { isMobile } from "react-device-detect";
import Lottie from "react-lottie";
import Loading from "../../../util/Loading";

const LandingPage = () => {
  const setModelInHeader = useTransitionStore((s) => s.setModelInHeader);
  const setHeaderFixed = useTransitionStore((s) => s.setHeaderFixed);
  const showScrollIcon = useTransitionStore((s) => s.showScrollIcon);

  useEffect(() => {
    setModelInHeader(false);
    setHeaderFixed(true);
    document.body.style.overflowY = "scroll";
    return () => (document.body.style.overflow = "hidden");
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <Header />
      <SequenceContainer />
      <MotionConfig transition={{ duration: 1 }}>
        <AnimatePresence>
          {showScrollIcon && (
            <Container
              key="landing-scroll-container"
              exit={{ opacity: 0, y: 20 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <Lottie
                width={200}
                height={200}
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: isMobile ? swipe : scroll,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
              />
              <span>{isMobile ? "Swipe" : "Scroll"}</span>
            </Container>
          )}
        </AnimatePresence>
      </MotionConfig>
    </Suspense>
  );
};

const Container = styled(motion.div)`
  position: fixed;
  bottom: 40px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
  pointer-events: none;

  span {
    color: #fff;
    margin-top: -40px;
  }
`;

export default LandingPage;
