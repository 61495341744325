import React from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import Lottie from "react-lottie";
import loadingAnimation from "../assets/animations/loading.json";

const Loading = () => {
  return (
    <AnimatePresence>
      <LoadingOccluder
        key="loading-container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Lottie
          width={70}
          height={70}
          options={{
            loop: true,
            autoplay: true,
            animationData: loadingAnimation,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
        />
      </LoadingOccluder>
    </AnimatePresence>
  );
};

const LoadingOccluder = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 9000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  pointer-events: none;
`;

export default Loading;
