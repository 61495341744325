import React, { useRef, useEffect } from "react";
import { animated, useSpring, easings } from "@react-spring/web";
import { useNavigate } from "react-router-dom";
import useTransitionStore from "../../../state/transitions";
import { isMobile } from "react-device-detect";
import { useMobileOrientation } from "react-device-detect";

const PAGE_COUNT = 5;

function ScrollToRevealTextContainer({ count }) {
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const setHeaderFixed = useTransitionStore((s) => s.setHeaderFixed);
  const setOrbitMode = useTransitionStore((s) => s.setOrbitMode);
  const setDisplayMode = useTransitionStore((s) => s.setDisplayMode);
  const setShowScrollIcon = useTransitionStore((s) => s.setShowScrollIcon);

  const { isLandscape } = useMobileOrientation();

  const [text1Styles, text1Api] = useSpring(() => ({
    top: `38%`,
    opacity: "0%",
    config: {
      duration: 2500,
      easing: easings.easeInOutExpo,
    },
  }));
  const [text2Styles, text2Api] = useSpring(() => ({
    top: `42%`,
    opacity: "0%",
    config: {
      duration: 2500,
      easing: easings.easeInOutExpo,
    },
  }));
  const [text3Styles, text3Api] = useSpring(() => ({
    top: `42%`,
    opacity: "0%",

    config: {
      duration: 2500,
      easing: easings.easeInOutExpo,
    },
  }));
  const [text4Styles, text4Api] = useSpring(() => ({
    top: `42%`,
    opacity: "0%",
    config: {
      duration: 2500,
      easing: easings.easeInOutExpo,
    },
  }));

  const [btnStyles, btnApi] = useSpring(() => ({
    top: `100%`,
    opacity: "0%",
    config: {
      duration: 2500,
      easing: easings.easeInOutExpo,
    },
  }));

  const [titleStyles, titleApi] = useSpring(() => ({
    top: `50%`,
    opacity: "0%",
    config: {
      duration: 3200,
      easing: easings.easeInOutExpo,
    },
  }));

  const currentProgress = useRef();
  useEffect(() => {
    // * prevent reverse text reveal
    if (currentProgress.current > count) return;

    currentProgress.current = count;

    if (count >= 0.01) setShowScrollIcon(false);
    if (count > 0.82) {
      btnApi.start({
        top: isMobile ? (isLandscape ? "85%" : "70%") : `88%`,
        opacity: "100%",
      });
    } else if (count > 0.72) {
      titleApi.start({
        top: isMobile ? (isLandscape ? "32%" : "42%") : `26%`,
        opacity: "100%",
      });
    } else if (count >= 0.68) {
      text4Api.start({
        top: isMobile ? "25%" : `14%`,
        opacity: "0%",
      });
    } else if (count >= 0.51) {
      text3Api.start({ top: isMobile ? "30%" : `14%`, opacity: "0%" });
      text4Api.start({
        top: isMobile ? (isLandscape ? "30%" : "40%") : `32%`,
        opacity: "100%",
      });
    } else if (count >= 0.34) {
      text2Api.start({ top: isMobile ? "30%" : `14%`, opacity: "0%" });
      text3Api.start({
        top: isMobile ? (isLandscape ? "30%" : "40%") : `32%`,
        opacity: "100%",
      });
    } else if (count >= 0.17) {
      text1Api.start({ top: isMobile ? "30%" : `14%`, opacity: "0%" });
      text2Api.start({
        top: isMobile ? (isLandscape ? "30%" : "40%") : `32%`,
        opacity: "100%",
      });
    } else if (count >= 0.01) {
      text1Api.start({
        top: isMobile ? (isLandscape ? "30%" : "40%") : `32%`,
        opacity: "100%",
      });
    }
  }, [count]);

  return (
    <>
      <div ref={containerRef} style={Style_Container}>
        <div style={Style_Animation_Wrapper}>
          <animated.div className="text_container" style={text1Styles}>
            <h1>An image of innovation.</h1>
          </animated.div>
          <animated.div className="text_container" style={text2Styles}>
            <h1>Captivating from all angles.</h1>
          </animated.div>
          <animated.div className="text_container" style={text3Styles}>
            <h1>Distinctly crafted.</h1>
          </animated.div>
          <animated.div className="text_container" style={text4Styles}>
            <h1>Turbocharged and electrified.</h1>
          </animated.div>
          <animated.div style={btnStyles} className="button_wrapper">
            <button
              onClick={() => (
                setHeaderFixed(false),
                navigate("/main"),
                setOrbitMode(false),
                setDisplayMode("flipper")
              )}
              className="explore-btn"
              style={{ cursor: "pointer", fontWeight: "600" }}
            >
              Explore
            </button>
          </animated.div>
        </div>

        {new Array(PAGE_COUNT).fill(null).map((_, index) => (
          <div style={Style_Page} key={index} />
        ))}

        <div className="title_container">
          <animated.div style={titleStyles} className="title_wrapper">
            <h1 className="title" style={{ fontSize: "7vw" }}>
              2023
              <br />
              TOYOTA CROWN
            </h1>
          </animated.div>
        </div>
      </div>
    </>
  );
}

const Style_Container = {
  width: "100vw",
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
};

const Style_Page = {
  width: "100vw",
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
};

const Style_Animation_Wrapper = {
  width: "100%",
  height: "100%",
  position: "fixed",
  inset: 0,
  pointerEvents: "none",
  zIndex: 2,
};

export default React.memo(ScrollToRevealTextContainer);
