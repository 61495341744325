import React, { forwardRef, useEffect, useRef } from "react";
import { Skeleton } from "./ImageSkeleton";
import ImageArray from "./util/ImageArray";
import { isMobile } from "react-device-detect";

const ImageSequence = forwardRef(({ count }, ref) => {
  const newImages = ImageArray();
  const currentProgress = useRef(0);

  useEffect(() => {
    if (currentProgress.current > count || count >= 1) return;

    currentProgress.current = count;
  }, [count]);

  let index = Math.round(currentProgress.current * 1 * (newImages.length - 1));

  if (newImages[index][1] !== undefined) {
    if (newImages[index][1] === "loading") {
      return <Skeleton width="100%" height="100%" />;
    } else {
      return newImages.map((item, i) => (
        <span
          ref={ref}
          key={i}
          style={{
            display: i !== index ? "none" : "block",
            height: "100%",
            width: "100%",
            backgroundImage: `url('${item[0] ? item[0].src : null}')`,
            backgroundSize: isMobile ? "contain" : "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            position: "absolute",
            zIndex: "1",
          }}
        />
      ));
    }
  }
});

export default ImageSequence;
