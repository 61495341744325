import React from "react";
import styled from "styled-components";
import useCustomizerStore from "../../../state/customizer";
import Logo from "../../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import useTransitionStore from "../../../state/transitions";
import shallow from "zustand/shallow";
import { isMobile } from "react-device-detect";
import { defaultControlAngles } from "../../../util/defaultControlAngles";

const Header = () => {
  const model = useCustomizerStore((s) => s.model);
  const setModel = useCustomizerStore((s) => s.setModel);
  const setFutureSelectedHotspotDescription = useCustomizerStore(
    (s) => s.setFutureSelectedHotspotDescription
  );
  const setSelectedHotspot = useCustomizerStore((s) => s.setSelectedHotspot);
  const setFutureSelectedHotspotTitle = useCustomizerStore(
    (s) => s.setFutureSelectedHotspotTitle
  );
  const setfutureSelectedDisclaimer = useCustomizerStore(
    (s) => s.setfutureSelectedDisclaimer
  );
  const setSelectedDisclaimer = useCustomizerStore(
    (s) => s.setSelectedDisclaimer
  );

  const [
    setOrbitMode,
    setShowCar,
    setDisplayMode,
    DisplayMode,
    setShowInterior,
    setModelInHeader,
    setCamIndex,
    setHeaderFixed,
    HeaderFixed,
    ModelInHeader,
    setAzimuthAndPolarAngles,
    setHotspotControls,
    setCameraMoveToHotspot,
    setDayMode,
    setHotspotZoomed,
    setSuspensionMode,
  ] = useTransitionStore(
    (s) => [
      s.setOrbitMode,
      s.setShowCar,
      s.setDisplayMode,
      s.DisplayMode,
      s.setShowInterior,
      s.setModelInHeader,
      s.setCamIndex,
      s.setHeaderFixed,
      s.HeaderFixed,
      s.ModelInHeader,
      s.setAzimuthAndPolarAngles,
      s.setHotspotControls,
      s.setCameraMoveToHotspot,
      s.setDayMode,
      s.setHotspotZoomed,
      s.setSuspensionMode,
    ],
    shallow
  );

  const navigate = useNavigate();

  const titleArr = ["XLE", "Limited", "Platinum"];
  return (
    <HeaderContainer
      marginLeftImg={isMobile ? "0px" : null}
      style={{ position: HeaderFixed ? "fixed" : "fixed" }}
      height={isMobile ? "60px" : null}
    >
      <div>
        <img
          onClick={() => (
            setShowInterior(false),
            setDisplayMode("flipper"),
            setHeaderFixed(true),
            setModelInHeader(false),
            setShowCar(true),
            setOrbitMode(false),
            setCamIndex(0),
            setModel(0),
            setCameraMoveToHotspot(null),
            setAzimuthAndPolarAngles(defaultControlAngles),
            setHotspotControls(false),
            setHotspotZoomed(false),
            setSuspensionMode(false),
            setDayMode("night"),
            setSelectedHotspot(-1),
            setfutureSelectedDisclaimer(""),
            setSelectedDisclaimer(""),
            // setFutureSelectedHotspotDescription(""),
            // setFutureSelectedHotspotTitle(""),
            // setCameraMoveToHotspot(null),
            navigate("/")
          )}
          src={Logo}
          alt="Toyota Logo"
          width="170"
        />
        {DisplayMode !== "flipper" && DisplayMode !== "landing" && (
          <span>{titleArr[model]}</span>
        )}
      </div>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  top: 0;
  width: 100vw;
  background-color: #000;
  height: ${(props) => props.height || "104px"};
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 100;
  div {
    ${"" /* padding: 0 16px 0 16px; */}
    margin-right: 5%;
    margin-left: 5%;
    ${"" /* max-width: 1200px; */}
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 20px;
    }
    img {
      ${"" /* margin-left: ${(props) => props.marginLeftImg || "30px"}; */}
    }
  }
`;

export default React.memo(Header);
