import create from "zustand";

const useTransitionStore = create((set) => ({
  OrbitModeActive: false,
  setOrbitMode: (OrbitModeActive) => {
    set(() => ({ OrbitModeActive }));
  },
  ActiveTransition: false,
  setActiveTransition: (bool) => {
    set(() => ({ ActiveTransition: bool }));
  },

  DisplayMode: "flipper",
  setDisplayMode: (mode) => {
    set(() => ({ DisplayMode: mode }));
  },

  initialRender: false,
  setInitialRender: (bool) => {
    set(() => ({ initialRender: bool }));
  },

  ShowCar: true,
  setShowCar: (bool) => {
    set(() => ({ ShowCar: bool }));
  },

  ShowInterior: false,
  setShowInterior: (bool) => {
    set(() => ({ ShowInterior: bool }));
  },

  ShowScrollContainer: false,
  setShowScrollContainer: (bool) => {
    set(() => ({ ShowScrollContainer: bool }));
  },

  ModelInHeader: false,
  setModelInHeader: (bool) => {
    set(() => ({ ModelInHeader: bool }));
  },

  HeaderFixed: false,
  setHeaderFixed: (bool) => {
    set(() => ({ HeaderFixed: bool }));
  },

  showScrollIcon: true,
  setShowScrollIcon: (bool) => {
    set(() => ({ showScrollIcon: bool }));
  },

  WheelIndex: 0,
  setWheelIndex: (number) => {
    set(() => ({ WheelIndex: number }));
  },

  CamPlacement: [
    {
      label: "flipper",
      position: [0, 4.4, 7.8], //y old = 2.1
      fov: 40,
      target: [0, 0, 0],
      mobilePosition: [0, 5.32, 12.37], //y old = 3.32
      mobileFov: 55,
      landscapePosition: [0, 1.58, 5.91],
    }, //[0, 1.8, 6.45],
    {
      label: "exterior",
      position: [-4, 0.13, 4.31],
      fov: 40,
      target: [0, 0, 0],
      mobilePosition: [-5.62, 2.91, 6.82],
      mobileFov: 55,
      landscapePosition: [-3.3, -0.08, 3.22],
    },
    // { label: "exterior", position: [-8, 4, -8], fov: 20, target: [0, 0, 0] },
    {
      label: "interior",
      // position: [0, 0, 0],
      position: [7, 3.6, 0], //7
      fov: 70,
      target: [-0.5, 0, 0],
      mobilePosition: [7, 3.6, 0],
      mobileFov: 70,
      landscapePosition: [7, 3.6, 0],
    },
    {
      label: "suspension",
      position: [-0.42, 2.55, 5.21], //[2.85, 2.6, 4],
      fov: 40,
      target: [0, 0, 0],
      mobilePosition: [2.85, 2.6, 2],
      mobileFov: 55,
      landscapePosition: [2.85, 1.6, 2],
    },

    {
      label: "powertrain",
      // position: [5.735, 7.1, 7.78],
      position: [2.85, 2.6, 4],
      fov: 40,
      target: [0, 0, 0],
      mobilePosition: [-7.04, 3.95, 7.05],
      mobileFov: 55,
      landscapePosition: [2.85, 1.6, 2],
    },
  ],
  CamIndex: 0,
  setCamIndex: (num) => {
    set(() => ({ CamIndex: num }));
  },

  UpdateAssets: false,
  setUpdateAssets: (bool) => {
    set(() => ({ UpdateAssets: bool }));
  },

  CameraSide: "driver", // passenger or driver
  setCameraSide: (side) => {
    set(() => ({ CameraSide: side }));
  },

  DayMode: "night",
  setDayMode: (str) => {
    set(() => ({ DayMode: str }));
  },

  DisableDayModeToggle: false,
  setDisableDayModeToggle: (bool) => {
    set(() => ({ DisableDayModeToggle: bool }));
  },

  layout: "",
  setLayout: (layout) => {
    set(() => ({ layout: layout }));
  },

  ResetAutoRotateDelta: false,
  triggerResetAutoRotateDelta: (bool) => {
    set(() => ({ ResetAutoRotateDelta: bool }));
  },

  AutoRotate: false,
  setAutoRotate: (bool) => {
    set(() => ({ AutoRotate: bool }));
  },

  // Hotspots
  AzimuthAndPolarAngles: {
    minAzimuthAngle: Infinity,
    maxAzimuthAngle: Infinity,
    minPolarAngle: null,
    maxPolarAngle: Math.PI / 1.95,
  },
  setAzimuthAndPolarAngles: (obj) => {
    set(() => ({ AzimuthAndPolarAngles: obj }));
  },

  TempAzimuthAndPolarAngles: {},
  setTempAzimuthAndPolarAngles: (obj) => {
    set(() => ({ TempAzimuthAndPolarAngles: obj }));
  },

  AzimuthAndPolarAnglesNeedUpdate: false,
  setAzimuthAndPolarAnglesNeedUpdate: (bool) => {
    set(() => ({ AzimuthAndPolarAnglesNeedUpdate: bool }));
  },

  HotspotControls: false,
  setHotspotControls: (bool) => {
    set(() => ({ HotspotControls: bool }));
  },

  ShowHotspots: true,
  setShowHotspots: (bool) => {
    set(() => ({ ShowHotspots: bool }));
  },

  CameraMoveToHotspot: null,
  setCameraMoveToHotspot: (data) => {
    set(() => ({ CameraMoveToHotspot: data }));
  },

  TriggerExitHotspot: false,
  setTriggerExitHotspot: (flag) => {
    set(() => ({ TriggerExitHotspot: flag }));
  },

  HotspotZoomed: false,
  setHotspotZoomed: (flag) => {
    set(() => ({ HotspotZoomed: flag }));
  },

  // Suspension
  SelectedSuspensionAnimation: "standard",
  setSelectedSuspensionAnimation: (mode) => {
    set(() => ({ SelectedSuspensionAnimation: mode }));
  },

  SuspensionMode: false,
  setSuspensionMode: (bool) => {
    set(() => ({ SuspensionMode: bool }));
  },
}));

export default useTransitionStore;
