import { LandingSequence } from "../../../../assets/images/landingSequence2kc";
import { useImage } from "../../../../hooks/useImage";

const LandingSequenceArray = () => {
	const [AA0, AA0S] = useImage(LandingSequence[0]);
	const [AA1, AA1S] = useImage(LandingSequence[1]);
	const [AA2, AA2S] = useImage(LandingSequence[2]);
	const [AA3, AA3S] = useImage(LandingSequence[3]);
	const [AA4, AA4S] = useImage(LandingSequence[4]);
	const [AA5, AA5S] = useImage(LandingSequence[5]);
	const [AA6, AA6S] = useImage(LandingSequence[6]);
	const [AA7, AA7S] = useImage(LandingSequence[7]);
	const [AA8, AA8S] = useImage(LandingSequence[8]);
	const [AA9, AA9S] = useImage(LandingSequence[9]);

	const [AA10, AA10S] = useImage(LandingSequence[10]);
	const [AA11, AA11S] = useImage(LandingSequence[11]);
	const [AA12, AA12S] = useImage(LandingSequence[12]);
	const [AA13, AA13S] = useImage(LandingSequence[13]);
	const [AA14, AA14S] = useImage(LandingSequence[14]);
	const [AA15, AA15S] = useImage(LandingSequence[15]);
	const [AA16, AA16S] = useImage(LandingSequence[16]);
	const [AA17, AA17S] = useImage(LandingSequence[17]);
	const [AA18, AA18S] = useImage(LandingSequence[18]);
	const [AA19, AA19S] = useImage(LandingSequence[19]);

	const [AA20, AA20S] = useImage(LandingSequence[20]);
	const [AA21, AA21S] = useImage(LandingSequence[21]);
	const [AA22, AA22S] = useImage(LandingSequence[22]);
	const [AA23, AA23S] = useImage(LandingSequence[23]);
	const [AA24, AA24S] = useImage(LandingSequence[24]);
	const [AA25, AA25S] = useImage(LandingSequence[25]);
	const [AA26, AA26S] = useImage(LandingSequence[26]);
	const [AA27, AA27S] = useImage(LandingSequence[27]);
	const [AA28, AA28S] = useImage(LandingSequence[28]);
	const [AA29, AA29S] = useImage(LandingSequence[29]);

	const [AA30, AA30S] = useImage(LandingSequence[30]);
	const [AA31, AA31S] = useImage(LandingSequence[31]);
	const [AA32, AA32S] = useImage(LandingSequence[32]);
	const [AA33, AA33S] = useImage(LandingSequence[33]);
	const [AA34, AA34S] = useImage(LandingSequence[34]);
	const [AA35, AA35S] = useImage(LandingSequence[35]);
	const [AA36, AA36S] = useImage(LandingSequence[36]);
	const [AA37, AA37S] = useImage(LandingSequence[37]);
	const [AA38, AA38S] = useImage(LandingSequence[38]);
	const [AA39, AA39S] = useImage(LandingSequence[39]);

	const [AA40, AA40S] = useImage(LandingSequence[40]);
	const [AA41, AA41S] = useImage(LandingSequence[41]);
	const [AA42, AA42S] = useImage(LandingSequence[42]);
	const [AA43, AA43S] = useImage(LandingSequence[43]);
	const [AA44, AA44S] = useImage(LandingSequence[44]);
	const [AA45, AA45S] = useImage(LandingSequence[45]);
	const [AA46, AA46S] = useImage(LandingSequence[46]);
	const [AA47, AA47S] = useImage(LandingSequence[47]);
	const [AA48, AA48S] = useImage(LandingSequence[48]);
	const [AA49, AA49S] = useImage(LandingSequence[49]);

	const [AA50, AA50S] = useImage(LandingSequence[50]);
	const [AA51, AA51S] = useImage(LandingSequence[51]);
	const [AA52, AA52S] = useImage(LandingSequence[52]);
	const [AA53, AA53S] = useImage(LandingSequence[53]);
	const [AA54, AA54S] = useImage(LandingSequence[54]);
	const [AA55, AA55S] = useImage(LandingSequence[55]);
	const [AA56, AA56S] = useImage(LandingSequence[56]);
	const [AA57, AA57S] = useImage(LandingSequence[57]);
	const [AA58, AA58S] = useImage(LandingSequence[58]);
	const [AA59, AA59S] = useImage(LandingSequence[59]);

	const [AA60, AA60S] = useImage(LandingSequence[60]);
	const [AA61, AA61S] = useImage(LandingSequence[61]);
	const [AA62, AA62S] = useImage(LandingSequence[62]);
	const [AA63, AA63S] = useImage(LandingSequence[63]);
	const [AA64, AA64S] = useImage(LandingSequence[64]);
	const [AA65, AA65S] = useImage(LandingSequence[65]);
	const [AA66, AA66S] = useImage(LandingSequence[66]);
	const [AA67, AA67S] = useImage(LandingSequence[67]);
	const [AA68, AA68S] = useImage(LandingSequence[68]);
	const [AA69, AA69S] = useImage(LandingSequence[69]);

	const [AA70, AA70S] = useImage(LandingSequence[70]);
	const [AA71, AA71S] = useImage(LandingSequence[71]);
	const [AA72, AA72S] = useImage(LandingSequence[72]);
	const [AA73, AA73S] = useImage(LandingSequence[73]);
	const [AA74, AA74S] = useImage(LandingSequence[74]);
	const [AA75, AA75S] = useImage(LandingSequence[75]);
	const [AA76, AA76S] = useImage(LandingSequence[76]);
	const [AA77, AA77S] = useImage(LandingSequence[77]);
	const [AA78, AA78S] = useImage(LandingSequence[78]);
	const [AA79, AA79S] = useImage(LandingSequence[79]);

	const [AA80, AA80S] = useImage(LandingSequence[80]);
	const [AA81, AA81S] = useImage(LandingSequence[81]);
	const [AA82, AA82S] = useImage(LandingSequence[82]);
	const [AA83, AA83S] = useImage(LandingSequence[83]);
	const [AA84, AA84S] = useImage(LandingSequence[84]);
	const [AA85, AA85S] = useImage(LandingSequence[85]);
	const [AA86, AA86S] = useImage(LandingSequence[86]);
	const [AA87, AA87S] = useImage(LandingSequence[87]);
	const [AA88, AA88S] = useImage(LandingSequence[88]);
	const [AA89, AA89S] = useImage(LandingSequence[89]);

	const [AA90, AA90S] = useImage(LandingSequence[90]);
	const [AA91, AA91S] = useImage(LandingSequence[91]);
	const [AA92, AA92S] = useImage(LandingSequence[92]);
	const [AA93, AA93S] = useImage(LandingSequence[93]);
	const [AA94, AA94S] = useImage(LandingSequence[94]);
	const [AA95, AA95S] = useImage(LandingSequence[95]);
	const [AA96, AA96S] = useImage(LandingSequence[96]);
	const [AA97, AA97S] = useImage(LandingSequence[97]);
	const [AA98, AA98S] = useImage(LandingSequence[98]);
	const [AA99, AA99S] = useImage(LandingSequence[99]);

	const [AA100, AA100S] = useImage(LandingSequence[100]);
	const [AA101, AA101S] = useImage(LandingSequence[101]);
	const [AA102, AA102S] = useImage(LandingSequence[102]);
	const [AA103, AA103S] = useImage(LandingSequence[103]);
	const [AA104, AA104S] = useImage(LandingSequence[104]);
	const [AA105, AA105S] = useImage(LandingSequence[105]);
	const [AA106, AA106S] = useImage(LandingSequence[106]);
	const [AA107, AA107S] = useImage(LandingSequence[107]);
	const [AA108, AA108S] = useImage(LandingSequence[108]);
	const [AA109, AA109S] = useImage(LandingSequence[109]);

	const [AA110, AA110S] = useImage(LandingSequence[110]);
	const [AA111, AA111S] = useImage(LandingSequence[111]);
	const [AA112, AA112S] = useImage(LandingSequence[112]);
	const [AA113, AA113S] = useImage(LandingSequence[113]);
	const [AA114, AA114S] = useImage(LandingSequence[114]);
	const [AA115, AA115S] = useImage(LandingSequence[115]);
	const [AA116, AA116S] = useImage(LandingSequence[116]);
	const [AA117, AA117S] = useImage(LandingSequence[117]);
	const [AA118, AA118S] = useImage(LandingSequence[118]);
	const [AA119, AA119S] = useImage(LandingSequence[119]);

	const [AA120, AA120S] = useImage(LandingSequence[120]);
	const [AA121, AA121S] = useImage(LandingSequence[121]);
	const [AA122, AA122S] = useImage(LandingSequence[122]);
	const [AA123, AA123S] = useImage(LandingSequence[123]);
	const [AA124, AA124S] = useImage(LandingSequence[124]);
	const [AA125, AA125S] = useImage(LandingSequence[125]);
	const [AA126, AA126S] = useImage(LandingSequence[126]);
	const [AA127, AA127S] = useImage(LandingSequence[127]);
	const [AA128, AA128S] = useImage(LandingSequence[128]);
	const [AA129, AA129S] = useImage(LandingSequence[129]);

	const [AA130, AA130S] = useImage(LandingSequence[130]);
	const [AA131, AA131S] = useImage(LandingSequence[131]);
	const [AA132, AA132S] = useImage(LandingSequence[132]);
	const [AA133, AA133S] = useImage(LandingSequence[133]);
	const [AA134, AA134S] = useImage(LandingSequence[134]);
	const [AA135, AA135S] = useImage(LandingSequence[135]);
	const [AA136, AA136S] = useImage(LandingSequence[136]);
	const [AA137, AA137S] = useImage(LandingSequence[137]);
	const [AA138, AA138S] = useImage(LandingSequence[138]);
	const [AA139, AA139S] = useImage(LandingSequence[139]);

	const [AA140, AA140S] = useImage(LandingSequence[140]);
	const [AA141, AA141S] = useImage(LandingSequence[141]);
	const [AA142, AA142S] = useImage(LandingSequence[142]);
	const [AA143, AA143S] = useImage(LandingSequence[143]);
	const [AA144, AA144S] = useImage(LandingSequence[144]);
	const [AA145, AA145S] = useImage(LandingSequence[145]);
	const [AA146, AA146S] = useImage(LandingSequence[146]);
	const [AA147, AA147S] = useImage(LandingSequence[147]);
	const [AA148, AA148S] = useImage(LandingSequence[148]);
	const [AA149, AA149S] = useImage(LandingSequence[149]);

	const [AA150, AA150S] = useImage(LandingSequence[150]);
	const [AA151, AA151S] = useImage(LandingSequence[151]);
	const [AA152, AA152S] = useImage(LandingSequence[152]);
	const [AA153, AA153S] = useImage(LandingSequence[153]);
	const [AA154, AA154S] = useImage(LandingSequence[154]);
	const [AA155, AA155S] = useImage(LandingSequence[155]);
	const [AA156, AA156S] = useImage(LandingSequence[156]);
	const [AA157, AA157S] = useImage(LandingSequence[157]);
	const [AA158, AA158S] = useImage(LandingSequence[158]);
	const [AA159, AA159S] = useImage(LandingSequence[159]);

	const [AA160, AA160S] = useImage(LandingSequence[160]);
	const [AA161, AA161S] = useImage(LandingSequence[161]);

	const newImages = Array.of(
		[AA0, AA0S],
		[AA1, AA1S],
		[AA2, AA2S],
		[AA3, AA3S],
		[AA4, AA4S],
		[AA5, AA5S],
		[AA6, AA6S],
		[AA7, AA7S],
		[AA8, AA8S],
		[AA9, AA9S],
		[AA10, AA10S],
		[AA11, AA11S],
		[AA12, AA12S],
		[AA13, AA13S],
		[AA14, AA14S],
		[AA15, AA15S],
		[AA16, AA16S],
		[AA17, AA17S],
		[AA18, AA18S],
		[AA19, AA19S],
		[AA20, AA20S],
		[AA21, AA21S],
		[AA22, AA22S],
		[AA23, AA23S],
		[AA24, AA24S],
		[AA25, AA25S],
		[AA26, AA26S],
		[AA27, AA27S],
		[AA28, AA28S],
		[AA29, AA29S],

		[AA30, AA30S],
		[AA31, AA31S],
		[AA32, AA32S],
		[AA33, AA33S],
		[AA34, AA34S],
		[AA35, AA35S],
		[AA36, AA36S],
		[AA37, AA37S],
		[AA38, AA38S],
		[AA39, AA39S],

		[AA40, AA40S],
		[AA41, AA41S],
		[AA42, AA42S],
		[AA43, AA43S],
		[AA44, AA44S],
		[AA45, AA45S],
		[AA46, AA46S],
		[AA47, AA47S],
		[AA48, AA48S],
		[AA49, AA49S],

		[AA50, AA50S],
		[AA51, AA51S],
		[AA52, AA52S],
		[AA53, AA53S],
		[AA54, AA54S],
		[AA55, AA55S],
		[AA56, AA56S],
		[AA57, AA57S],
		[AA58, AA58S],
		[AA59, AA59S],

		[AA60, AA60S],
		[AA61, AA61S],
		[AA62, AA62S],
		[AA63, AA63S],
		[AA64, AA64S],
		[AA65, AA65S],
		[AA66, AA66S],
		[AA67, AA67S],
		[AA68, AA68S],
		[AA69, AA69S],

		[AA70, AA70S],
		[AA71, AA71S],
		[AA72, AA72S],
		[AA73, AA73S],
		[AA74, AA74S],
		[AA75, AA75S],
		[AA76, AA76S],
		[AA77, AA77S],
		[AA78, AA78S],
		[AA79, AA79S],

		[AA80, AA80S],
		[AA81, AA81S],
		[AA82, AA82S],
		[AA83, AA83S],
		[AA84, AA84S],
		[AA85, AA85S],
		[AA86, AA86S],
		[AA87, AA87S],
		[AA88, AA88S],
		[AA89, AA89S],

		[AA90, AA90S],
		[AA91, AA91S],
		[AA92, AA92S],
		[AA93, AA93S],
		[AA94, AA94S],
		[AA95, AA95S],
		[AA96, AA96S],
		[AA97, AA97S],
		[AA98, AA98S],
		[AA99, AA99S],

		[AA100, AA100S],
		[AA101, AA101S],
		[AA102, AA102S],
		[AA103, AA103S],
		[AA104, AA104S],
		[AA105, AA105S],
		[AA106, AA106S],
		[AA107, AA107S],
		[AA108, AA108S],
		[AA109, AA109S],

		[AA110, AA110S],
		[AA111, AA111S],
		[AA112, AA112S],
		[AA113, AA113S],
		[AA114, AA114S],
		[AA115, AA115S],
		[AA116, AA116S],
		[AA117, AA117S],
		[AA118, AA118S],
		[AA119, AA119S],

		[AA120, AA120S],
		[AA121, AA121S],
		[AA122, AA122S],
		[AA123, AA123S],
		[AA124, AA124S],
		[AA125, AA125S],
		[AA126, AA126S],
		[AA127, AA127S],
		[AA128, AA128S],
		[AA129, AA129S],

		[AA130, AA130S],
		[AA131, AA131S],
		[AA132, AA132S],
		[AA133, AA133S],
		[AA134, AA134S],
		[AA135, AA135S],
		[AA136, AA136S],
		[AA137, AA137S],
		[AA138, AA138S],
		[AA139, AA139S],

		[AA140, AA140S],
		[AA141, AA141S],
		[AA142, AA142S],
		[AA143, AA143S],
		[AA144, AA144S],
		[AA145, AA145S],
		[AA146, AA146S],
		[AA147, AA147S],
		[AA148, AA148S],
		[AA149, AA149S],

		[AA150, AA150S],
		[AA151, AA151S],
		[AA152, AA152S],
		[AA153, AA153S],
		[AA154, AA154S],
		[AA155, AA155S],
		[AA156, AA156S],
		[AA157, AA157S],
		[AA158, AA158S],
		[AA159, AA159S],

		[AA160, AA160S],
		[AA161, AA161S]
	);
	return newImages;
};

export default LandingSequenceArray;
